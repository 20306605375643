import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import { AuthContextProvider } from "./context/AuthContext";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Account from "./pages/Account";
import ProtectedRoute from "./components/ProtectedRoute";
import Video from "./pages/Video";
import Vod from "./pages/Vod";
import LiveVideo from "./pages/LiveVideo";
import { query } from "firebase/database";
import { collection, limit, onSnapshot, where } from "firebase/firestore";
import { db } from "./firebase";
import { useEffect, useState } from "react";
import { isValidTimestamp } from "@firebase/util";

function App() {
  const [feed, setFeed] = useState();
  const [loading, setLoading] = useState(false);

  function loadScript(src) {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    document.body.appendChild(script);
  }

  const player_src = "https://cdn.jwplayer.com/libraries/iiFHvtll.js";

  loadScript(player_src);

  useEffect(() => {
    loadScript(player_src);

    /* jwplayer("videoPlayer").setup({
      playlist: [
        {
          sources: [
            {
              file: dashStreamURL,
              drm: {
                widevine: {
                  url: "https://widevine-license.vudrm.tech/proxy",
                  headers: [
                    {
                      name: "X-VUDRM-TOKEN",
                      value: vudrmToken,
                    },
                  ],
                },
              },
            },
          ],
        },
      ],
    }); */
  }, []);

  /*   const q = query(
    collection(db, "feeds"),
    where("title", "!=", "AAA"),
    limit(1)
  );
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    setLoading(true);
    const items = [];
    querySnapshot.forEach((doc) => {
      //items.push(doc.data());
      console.log(doc.data());
      //setFeed(items);
    });
    setLoading(false);
  }); */

  return (
    <>
      <AuthContextProvider>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/video" element={<Video />} />
          <Route path="/vod" element={<Vod />} />
          <Route path="/live-video" element={<LiveVideo />} />
          <Route
            path="/account"
            element={
              <ProtectedRoute>
                <Account />
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthContextProvider>
    </>
  );
}

export default App;
