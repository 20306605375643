import React from "react";
import Main from "../components/Main";
import Vod from "../components/Vod";
import AllHands from "../components/AllHands";

const Home = () => {
  return (
    <>
      <Main />
      <Vod />
      <AllHands />

      {/*  
      <Bites />
      <AgilityDays />
      <Live />

      <Events
        rowID="1"
        title="All Hands"
        assets="all_hands"
        fetchURL={requests.requestUpcoming}
      />
      <Events
        rowID="2"
        title="Hack Days"
        assets="hack"
        fetchURL={requests.requestUpcoming}
      />
      <Events
        rowID="3"
        title="Agility Days"
        assets="agility"
        fetchURL={requests.requestUpcoming}
      />
      <Events
        rowID="4"
        title="Bites"
        assets="bites"
        fetchURL={requests.requestUpcoming}
      /> */}

      {/* <Row rowID="10" title="UpComing" fetchURL={requests.requestUpcoming} />
      <Row rowID="2" title="Popular" fetchURL={requests.requestPopular} />
      <Row rowID="3" title="Trending" fetchURL={requests.requestTrending} />
      <Row rowID="4" title="Top Rated" fetchURL={requests.requestTopRated} />
      <Row rowID="5" title="Horror" fetchURL={requests.requestHorror} /> */}
    </>
  );
};

export default Home;
