import axios from "axios";
import React, { useEffect, useState } from "react";
import requests from "../Requests";
import background from "../assets/background.png";
import Banner from "./Banner";
import { Link } from "react-router-dom";
import { query } from "firebase/database";
import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  QuerySnapshot,
  where,
} from "firebase/firestore";
import { db } from "../firebase";

const Main = () => {
  const [movies, setMovies] = useState([]);

  const [title, setTitle] = useState([]);
  const [content_id, setContentId] = useState([]);
  const [feed, setFeed] = useState([]);
  const [description, setDescription] = useState([]);
  const [logo, setLogo] = useState([]);
  const [release_date, setReleaseDate] = useState([]);

  const movie = movies[Math.floor(Math.random() * movies.length)];

  var test = null;

  useEffect(() => {
    /*axios.get(requests.requestPopular).then((response) => {
      setMovies(response.data.results);
    });*/
  }, []);

  const truncateString = (str, num) => {
    if (str?.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  };

  /*   const fetchFeed = async () => {
    await getDocs(collection(db, "feeds")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: Math.random(),
      }));
      //console.log(newData);
      setFeed({ newData });
    });
  };

  useEffect(() => {
    fetchFeed();
    console.log(feed);
  }, []); */

  const q = query(
    collection(db, "feeds"),
    where("title", "!=", "AAA"),
    limit(1)
  );
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      setTitle(doc.data().title);
      setDescription(doc.data().description);
      setFeed(doc.data().url);
      setLogo(doc.data().logo);
      setContentId(doc.data().content_id);
      setReleaseDate(doc.data().release_date);
    });
  });

  return (
    <div className="w-full h-[500px] text-white">
      <div className="w-full h-full">
        <div className="absolute w-full h-[500px] bg-gradient-to-r from-black"></div>
        <img
          className="w-full h-full object-cover"
          src={background}
          //src={`https://image.tmdb.org/t/p/original/${movie?.backdrop_path}`}
          alt={movie?.title}
        />
        <div className="absolute w-full top-[15%] p-4 md:p-8">
          <h1 className="text-3xl md:text-5xl font-bold">{title}</h1>
          <div className="my-4">
            <button className="border bg-gray-300 text-black border-gray-300 py-2 px-5 cursor-auto">
              Scheduled
            </button>
            <button className="border text-white border-gray-300 py-2 px-5 ml-4 cursor-auto">
              {release_date}
            </button>
            {feed ? (
              <Link
                to="/live-video"
                state={{
                  title: title,
                  description: description,
                  url: feed,
                  content_id: content_id,
                  logo: logo,
                  release_date: release_date,
                }}
              >
                <button className="border bg-red-700 text-white border-gray-300 py-2 px-5 ml-4">
                  Live Now
                </button>
              </Link>
            ) : (
              ""
            )}
          </div>
          <p className="text-gray-400 text-sm"></p>
          <p className="w-full md:max-w-[70%] lg:max-w-[50%] xl:max-w-[35%] text-gray-200">
            {truncateString(description, 350)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Main;
