import React, { useEffect, useState } from "react";
import Movie from "./Movie";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { Link } from "react-router-dom";

const AllHands = () => {
  const slideLeft = () => {
    var slider = document.getElementById("sliderah");
    slider.scrollLeft = slider.scrollLeft - 500;
  };
  const slideRight = () => {
    var slider = document.getElementById("sliderah");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", { month: "long" });
  }

  function randomDate(start, end) {
    return new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    ).toLocaleString();
  }

  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  return (
    <>
      <h2 className="text-white md:text-2xl p-2 tracking-widest">
        <a className="text-red-700 font-bold">latest</a>
        <a className="font-thin">events</a>
      </h2>
      <div className="relative flex items-center group">
        <MdChevronLeft
          onClick={slideLeft}
          className="bg-white left-0 rounded-full absolute opacity-50 hover:opacity-100 cursor-pointer z-10 hidden group-hover:block"
          size={40}
        />
        <div
          id={"sliderah"}
          className="w-full h-full overflow-x-scroll whitespace-nowrap scroll-smooth scrollbar-hide relative"
        >
          {items.map((item) => (
            <Link
              to="/video"
              state={{
                type: "All Hands",
                id: item,
                name: getMonthName(item),
                date: randomDate(new Date(2018, 1, 1), new Date()),
                url: "./assets/videos/trends.mp4",
              }}
              key={Math.random()}
            >
              <div
                className="w-[160px] sm:w-[200px] md:w-[240px] lg:w-[280px] inline-block cursor-pointer relative p-2"
                id={Math.random()}
              >
                <img
                  className="w-full h-auto block"
                  src={`./assets/events/others/${Math.ceil(
                    Math.random() * 5
                  )}.png`}
                  //src={`https://picsum.photos/280/160?random=${item}`}
                  alt={item}
                />

                <div className="absolute top-0 left-0 w-full h-full hover:bg-black/80 opacity-0 hover:opacity-100 text-white">
                  <p className="white-space-normal text-xs md:text-sm font-bold flex justify-center items-center h-full text-center">
                    {getMonthName(item)}
                  </p>
                  <p>
                    {0 ? (
                      <FaHeart className="absolute top-4 left-4 text-gray-300" />
                    ) : (
                      <FaRegHeart className="absolute top-4 left-4 text-gray-300" />
                    )}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <MdChevronRight
          onClick={slideRight}
          className="bg-white right-0 rounded-full absolute opacity-50 hover:opacity-100 cursor-pointer z-10 hidden group-hover:block"
          size={40}
        />
      </div>
    </>
  );
};

export default AllHands;
