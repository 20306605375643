import React, { useEffect, useState } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { Link } from "react-router-dom";

const Vod = () => {
  const slideLeft = () => {
    var slider = document.getElementById("sliderahVod");
    slider.scrollLeft = slider.scrollLeft - 500;
  };
  const slideRight = () => {
    var slider = document.getElementById("sliderahVod");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", { month: "long" });
  }
  function getFeedInfo(number) {
    if (number % 2 === 0) {
      return {
        title: "Big Buck Bunny",
        description: "Big Buck Bunny",
        url: "https://dtv-arch-vod-transcoded-assets.s3.eu-west-1.amazonaws.com/BigBuckBunny/bunny_1080p_60fps.mpd",
        content_id: "BigBuckBunny",
      };
    } else {
      return {
        title: "RTP HD",
        description: "RTP HD",
        url: "https://dtv-arch-vod-transcoded-assets.s3.eu-west-1.amazonaws.com/RTP1DRM/RTP1HD_1080p_50fps.mpd",
        content_id: "rtpdrm",
      };
    }
  }

  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  return (
    <>
      <h2 className="text-white md:text-2xl p-2 tracking-widest">
        <a className="text-red-700 font-bold">premium</a>
        <a className="font-thin">content</a> |{" "}
        <a className="text-red-700 font-bold">V</a>
        <a className="font-thin text-white">O</a>
        <a className="text-red-700 font-bold">D</a>
      </h2>
      <div className="relative flex items-center group">
        <MdChevronLeft
          onClick={slideLeft}
          className="bg-white left-0 rounded-full absolute opacity-50 hover:opacity-100 cursor-pointer z-10 hidden group-hover:block"
          size={40}
        />
        <div
          id={"sliderahVod"}
          className="w-full h-full overflow-x-scroll whitespace-nowrap scroll-smooth scrollbar-hide relative"
        >
          {items.map((item) => (
            <Link
              to="/vod"
              key={item * 100}
              state={{
                title: getFeedInfo(item).title,
                description: getFeedInfo(item).description,
                url: getFeedInfo(item).url,
                content_id: getFeedInfo(item).content_id,
              }}
              //state={{ type: "VOD", id: item, name: getFeedInfo(item) }}
            >
              <div
                className="w-[160px] sm:w-[200px] md:w-[240px] lg:w-[280px] inline-block cursor-pointer relative p-2"
                id={item * 100}
                key={item * 100}
              >
                <img
                  className="w-full h-auto block"
                  src={`https://picsum.photos/280/160?random=${item}`}
                  alt={item}
                />

                <div className="absolute top-0 left-0 w-full h-full hover:bg-black/80 opacity-0 hover:opacity-100 text-white">
                  <p className="white-space-normal text-xs md:text-sm font-bold flex justify-center items-center h-full text-center">
                    {getFeedInfo(item).description}
                  </p>
                  <p>
                    {0 ? (
                      <FaHeart className="absolute top-4 left-4 text-gray-300" />
                    ) : (
                      <FaRegHeart className="absolute top-4 left-4 text-gray-300" />
                    )}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <MdChevronRight
          onClick={slideRight}
          className="bg-white right-0 rounded-full absolute opacity-50 hover:opacity-100 cursor-pointer z-10 hidden group-hover:block"
          size={40}
        />
      </div>
    </>
  );
};

export default Vod;
