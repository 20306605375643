import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "video.js/dist/video-js.css";
import VideoJS from "../components/VideoJS";

const Video = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  const playerRef = React.useRef(null);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    hls: {
      overrideNative: true,
    },
    nativeAudioTracks: false,
    nativeVideoTracks: false,
    sources: [
      {
        //src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4",
        src: data.url ? data.url : "./assets/videos/trends.mp4",
        //type: "video/mp4",
        //type: "application/x-mpegURL",
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      VideoJS.log("player is waiting");
    });

    player.on("dispose", () => {
      VideoJS.log("player will dispose");
    });
  };

  return (
    <>
      <div className="w-full text-white">
        <img
          className="w-full h-[400px] object-cover"
          src={`../assets/account_banner.png`}
          alt="/"
        />
        <div className="bg-black/60 fixed top-0 left-0 w-full h-[550px]"></div>
        <div className="absolute top-[6%] p-4 md:p-8 w-10/12">
          <button
            onClick={() => navigate(-1)}
            className="float-right bg-red-600 px-6 py-2 rounded cursor-pointer text-white"
          >
            Back
          </button>
          <h1 className="text-3xl md:text-5xl font-bold">{data.name}</h1>
          <p className="">{data.date}</p>
          <div className="">
            <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
