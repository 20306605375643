import axios from "axios";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import requests from "../Requests";

const Vod = (props) => {
  let navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  console.log(data);
  console.log(requests.requestToken);
  let vdt = "";
  var widevineLUrl = "https://widevine-license.vudrm.tech/proxy";

  useEffect(() => {
    const config = {};
    axios
      .get(requests.requestToken + "?content_id=" + data.content_id, config)
      .then((response) => {
        vdt = response.data.token;
        console.log(response.data);

        window.jwplayer("videoPlayer").setup({
          autostart: true,
          playlist: [
            {
              sources: [
                {
                  file: data.url,
                  drm: {
                    widevine: {
                      url: widevineLUrl,
                      headers: [
                        {
                          name: "X-VUDRM-TOKEN",
                          value: vdt,
                        },
                      ],
                    },
                  },
                },
              ],
            },
          ],
        });
      });
  }, []);

  return (
    <>
      <div className="w-full text-white">
        <img
          className="w-full h-[400px] object-cover"
          src={`../assets/account_banner.png`}
          alt="/"
        />
        <div className="bg-black/60 fixed top-0 left-0 w-full h-[550px]"></div>
        <div className="absolute top-[6%] p-4 md:p-8 w-10/12">
          <button
            onClick={() => navigate(-1)}
            className="float-right bg-red-600 px-6 py-2 rounded cursor-pointer text-white"
          >
            Back
          </button>
          <h1 className="text-3xl md:text-5xl font-bold">{data.title}</h1>
          <p className="">{data.release_date}</p>
          <div className="">
            <div id="videoPlayer" controls></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Vod;
